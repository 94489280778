import { specialSymbols } from "./lib";
import { tryCatchWrapper } from "./util";

// Currency formatting
interface ICurrencyValues {
    currencyPrecision: number;
    currencySymbol: string;
    currencyCode?: string;
}

const getCurrencyValuesFromConfig = (config: any): ICurrencyValues => {
    const currencyObj: ICurrencyValues = {} as ICurrencyValues;
    if (config.currencyPrecision) currencyObj.currencyPrecision = config.currencyPrecision;
    if (config.currencyCode) currencyObj.currencyCode = config.currencyCode;
    if (config.currency) currencyObj.currencySymbol = config.currency;
    return currencyObj;
};
// legacy currency formatting
const getLegacyCurrencySymbol = () => localStorage.getItem('PxCurrencySymbol') ?? '$';
const getLegacyCurrencyPrecision = () => +(localStorage.getItem('PxCurrencyPrecision') ?? '2');
const getLegacyCurrencyValuesFromLocalStorage = () => {
    return {
        currencyPrecision: getLegacyCurrencyPrecision(),
        currencySymbol: getLegacyCurrencySymbol(),
    };
};
const legacyCurrencyFormatter = (
    currencySymbol: string,
    numberFormatLocale: string | undefined,
    currencyPrecision: number,
    value: number
) => {
    const valueNF = new Intl.NumberFormat(numberFormatLocale ?? 'en', {
        useGrouping: true,
        minimumFractionDigits: currencyPrecision ?? 2,
    });
    return (currencySymbol ?? '$').concat(valueNF.format(value));
};
// end of legacy currency formatting
export const setCurrencyValuesToLocalStorage = (config: any) => {
    const currencyValues = getCurrencyValuesFromConfig(config);
    localStorage.setItem('PxCurrencyValues', JSON.stringify(currencyValues));
};

export const getCurrencyValuesFromLocalStorage = (): ICurrencyValues => {
    const currencyValues = localStorage.getItem('PxCurrencyValues');
    if (!currencyValues) return getLegacyCurrencyValuesFromLocalStorage();
    return tryCatchWrapper(() => JSON.parse(currencyValues), getLegacyCurrencyValuesFromLocalStorage);
};

export const setNumberFormatterToLocalStorage = (config: any) => {
    if (config.numberFormatLocale) localStorage.setItem('PxNumberFormatLocale', config.numberFormatLocale);
};

export const getNumberFormatterFromLocalStorage = (): string => localStorage.getItem('PxNumberFormatLocale') ?? 'en';

const getCurrencyLocaleFormatter = (
    currencyLocale?: string,
    currencyCode?: string,
    currencyPrecision?: number
) => {
    return new Intl.NumberFormat(currencyLocale ?? 'en', {
        style: 'currency',
        currency: currencyCode ?? 'USD',
        useGrouping: true,
        minimumFractionDigits: currencyPrecision ?? 2,
    });
};

const getApplicablePlusMinusSymbol = (value: number) => (value >= 0 ? '' : '-');

const _currencyFormatNum = (value: number, overrideCurrencySymbol?: string, overridePrecision?: number) => {
    const {
        currencyCode,
        currencySymbol,
        currencyPrecision,
    } = getCurrencyValuesFromLocalStorage();

    const numberFormatLocale = getNumberFormatterFromLocalStorage();

    if (overrideCurrencySymbol || !currencyCode)
        return getApplicablePlusMinusSymbol(value).concat(
            legacyCurrencyFormatter(overrideCurrencySymbol ?? currencySymbol, numberFormatLocale, overridePrecision ?? currencyPrecision, Math.abs(value))
        );

    return getApplicablePlusMinusSymbol(value).concat(
        getCurrencyLocaleFormatter(numberFormatLocale, currencyCode, overridePrecision ?? currencyPrecision).format(
            Math.abs(value)
        )
    );
};

const _currencyFormatStr = (value: string, overrideCurrencySymbol?: string) =>
    (overrideCurrencySymbol ?? getCurrencyValuesFromLocalStorage().currencySymbol ?? '$').concat(
        value
    );

interface ICurrencyFormatterMetaData {
    overrideCurrencySymbol?: string;
    overridePrecision?: number;
    showZeroValue?: boolean;
}
export const currencyFormatter = (value: any, { showZeroValue, overrideCurrencySymbol, overridePrecision }: ICurrencyFormatterMetaData) => {
    if ((!showZeroValue || (showZeroValue && value !== 0)) && !value) return '--';
    if (value === Infinity) return specialSymbols[value];
    if (typeof value === 'string') return _currencyFormatStr(value, overrideCurrencySymbol);
    return _currencyFormatNum(+value, overrideCurrencySymbol, overridePrecision);
};