import DOMPurify from 'dompurify';
import apiToUrlMap, { formatStringSignature } from '../ApiMapping';
import { IPOEDocumentServerResponse } from '../types/POETypes';
import { ParticipantWarehouse } from '../types/StockListTypes';
import {
  READY_FOR_AUTO_CHECKOUT,
  AWAITING_BUYER_REVIEW,
  AWAITING_TENANT_REVIEW,
  CART_TABS,
  OFFER_TABS,
  READY_TO_CHECKOUT,
} from '../types/enumToLabelsMap';
import { getShippingAddressIdRealTimeShippingMethodMapping } from '../components/OrdersComponents/OrderUtils';
import { Auth } from '@aws-amplify/auth';
import { INetworkSystemMessage } from '../types/INetworkSystemTypes';
import { getQueryClientServiceSignature } from '../hooks/useQueryCustomHooks/useDataService';

export const isEmpty = (data: Object | Array<any> | undefined | null): data is null | undefined => {
  if (!data) return true;
  if (data instanceof Array) return !data.length;
  if (data instanceof Object) return !Object.keys(data).length;
  return !data;
};

export const checkNullOrUndefined = (val: any) => {
  return val === null || val === undefined;
};

export const formatISODate = (date: string) => {
  return date
    .split(' ')
    .filter((_: any, idx: number) => {
      return idx < 4;
    })
    .join(' ');
};

export const logOutTheUser = async (
  message: string,
  severity: any,
  messageType?: any,
  cacheSave?: boolean,
  cb?: Function
) => {
  try {
    await Auth.signOut();
    if (!cacheSave) {
      localStorage.clear();
      window.indexedDB.deleteDatabase('d2');
    }
    localStorage.setItem(
      'customLoginLoadMessage',
      JSON.stringify({ message, severity, messageType })
    );
    cb && cb();
    //TODO try reload instead of href
    document.location.href = '/';
  } catch (error: any) {
    console.error('error signing out: ', error);
  }
};

interface ICheckoutPayload {
  pxParticipantId: string;
  isPxns: any;
  warehouseCode: string;
  offerIds: any;
  isNewOrder: boolean;
  payload: any;
}

export enum checkoutItem {
  pxParticipantId = 'pxParticipantId',
  isPxns = 'isPxns',
  checkoutOfferIds = 'checkoutOfferIds',
  checkoutWarehouseCode = 'checkoutWarehouseCode',
  checkoutPayload = 'checkoutPayload',
  addToExistingOfferIds = 'addToExistingOrderCheckoutOfferIds',
  addToExistingWarehouseCode = 'addToExistingOrderCheckoutWarehouseCode',
  addToExistingPayload = 'addToExistingOrderPayload',
}

export const setCheckoutPayload = ({
  pxParticipantId,
  isPxns,
  warehouseCode,
  offerIds,
  isNewOrder,
  payload,
}: ICheckoutPayload) => {
  localStorage.setItem(checkoutItem.pxParticipantId, pxParticipantId);
  localStorage.setItem(checkoutItem.isPxns, isPxns);

  let offerIdsKey = checkoutItem.checkoutOfferIds,
    warehouseCodeKey = checkoutItem.checkoutWarehouseCode,
    payloadKey = checkoutItem.checkoutPayload;
  if (!isNewOrder) {
    offerIdsKey = checkoutItem.addToExistingOfferIds;
    warehouseCodeKey = checkoutItem.addToExistingWarehouseCode;
    payloadKey = checkoutItem.addToExistingPayload;
  }

  localStorage.setItem(offerIdsKey, JSON.stringify(offerIds));
  localStorage.setItem(warehouseCodeKey, warehouseCode);

  return getCheckoutPayload(payload, offerIds, payloadKey, offerIdsKey, {
    pxParticipantId: pxParticipantId,
    isPxns: isPxns,
  });
};

export const getCheckoutPayload = (
  payload: any,
  offerIds: any,
  payloadKey: string,
  offerIdKey: string,
  additionalFields?: any
) => {
  if (payload?.fulfillmentSelectionAvailable && payload?.fulfillmentMethods) {
    const methods = Object.values(payload.fulfillmentMethods);
    methods.sort((m1: any, m2: any) => m1.sortOrder - m2.sortOrder);
    payload.fulfillmentMethods = methods.reduce((acc: any, method: any) => {
      acc[method.code] = method;
      return acc;
    }, {});
  }

  if (
    payload.realTimeQuoteShippingMethods &&
    Object.keys(payload.realTimeQuoteShippingMethods).length > 0
  ) {
    payload.otherShippingMethods = {
      ...payload.otherShippingMethods,
      ...JSON.parse(JSON.stringify(payload.realTimeQuoteShippingMethods)),
    };
  }

  payload.shippingAddressIdRealTimeShippingMethodMapping = getShippingAddressIdRealTimeShippingMethodMapping(
    payload
  );

  return {
    [payloadKey]: payload,
    [offerIdKey]: offerIds,
    ...additionalFields,
  };
};

export enum paymentMethodStatus {
  'SUCCESS' = 'SUCCESS',
  'FAILURE' = 'FAILURE',
}

export const payWithPaypal = (
  getQueryClientService: getQueryClientServiceSignature,
  formatString: formatStringSignature
) => async (phonexOrderNumber: any) => {
  try {
    // get approval link from server
    const res = await getQueryClientService({
      url: formatString(apiToUrlMap.getPaymentLink, {
        phonexOrderNumber,
      }),
    });

    return new Promise((resolve, reject) => {
      try {
        // message reciever
        const receiveMessage = (ev: MessageEvent<any>) => {
          if (!ev.data.status) return;
          const response = ev.data.confirmPaymentStatus
            ? paymentMethodStatus.SUCCESS
            : paymentMethodStatus.FAILURE;
          resolve(response);
          // removing the current message listener
          window.removeEventListener('message', receiveMessage);
        };
        // remove if any event listener already exists
        window.removeEventListener('message', receiveMessage);
        // open popup
        const windowRef = window.open(
          res.approvalLink,
          'ModalPopUp',
          'toolbar=no, menubar=no, width=600, height=700, top=100, left=100'
        );
        // waiting for message from popup to populate
        setTimeout(() => {
          if (windowRef) {
            // click event to focus paypal window
            const continueToPaypal = document.getElementById('px-continue-to-paypal');
            if (continueToPaypal)
              continueToPaypal.addEventListener('click', () => {
                windowRef.focus();
              });
            windowRef.focus();
            // adding event listener to receive message from popup
            window.addEventListener('message', receiveMessage, false);
            // timeout for checking if popup is closed
            var timer = setInterval(function () {
              if (windowRef.closed) {
                // clear interval, set status to failure
                clearInterval(timer);
                resolve(paymentMethodStatus.FAILURE);
                window.removeEventListener('message', receiveMessage);
              }
            }, 1000);
          }
        }, 100);
      } catch (error: any) {
        console.error('error while making payment', error.message);
        // if programatically generated popups are not allowed
        resolve(paymentMethodStatus.FAILURE);
      }
    });
  } catch (error: any) {
    console.error('error while fetching payment link', error.message);
    return paymentMethodStatus.FAILURE;
  }
};

export const getPendingProofDocuments = (documents: IPOEDocumentServerResponse[]) =>
  documents
    .filter((doc) => doc.documentStatus === 'REJECTED' || !doc.documentStatus)
    .map((doc) => ({
      ...doc,
      documentDescription: doc.documentDescription.split(/\n/g),
    }));

export const sanitizeHTML = (html: string) => {
  return DOMPurify.sanitize(html, {
    ALLOWED_TAGS: [
      'div',
      'h6',
      'strong',
      'em',
      's',
      'a',
      'span',
      'p',
      'br',
      'u',
      'ul',
      'li',
      'ol',
      'img',
      'small',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
    ],
    ADD_ATTR: ['target'],
  });
};

export const deepCopy = (obj: any) => {
  if (!obj) return obj;
  return JSON.parse(JSON.stringify(obj));
};

export const keys = <T extends Object>(obj: T | undefined) =>
  (obj ? Object.keys(obj) : []) as Array<keyof T>;

export const getPXNWarehouseObj = (warehouses: Array<ParticipantWarehouse>, userInfo: any) => {
  return warehouses?.reduce((final: any, val) => {
    const warehousesObj: any = {};
    val.warehouses.forEach((warehouse) => {
      warehousesObj[warehouse.warehouseId] = { ...warehouse };
    });
    final[val.pxnParticipantId] = {
      ...val,
      warehousesObj: { ...warehousesObj },
      promoted: Number(!!userInfo?.enabledTags[`PROMOTE_${val.pxnParticipantId}`]),
    };
    return { ...final };
  }, {});
};

export const getNetworkSystemMessageState = (stockListSettings: any) => {
  return Object.keys(stockListSettings).reduce((acc, participantId) => {
    acc[participantId] = stockListSettings[participantId].cartSettings;
    return acc;
  }, {} as INetworkSystemMessage);
};

const tabs = (I18n: any, tabBadgeCount: any, configState: any) => [
  {
    id: READY_TO_CHECKOUT,
    text: I18n._uppercase(I18n.cart || 'CART'),
    badgeContent: tabBadgeCount[READY_TO_CHECKOUT],
  },
  {
    id: AWAITING_BUYER_REVIEW,
    text: I18n._uppercase(I18n.offersAwaitReview || 'OFFERS AWAITING YOUR REVIEW'),
    badgeContent: tabBadgeCount[AWAITING_BUYER_REVIEW],
  },
  {
    id: AWAITING_TENANT_REVIEW,
    text: I18n._format(I18n.offersAwaitXReview || 'Offers Awaiting ${1} Review', [
      configState.tenantShortName || 'ACME',
    ]),
    badgeContent: tabBadgeCount[AWAITING_TENANT_REVIEW],
  },
];

const readyForAutoCheckoutTabObj = (I18n: any, tabBadgeCount: any) => ({
  id: READY_FOR_AUTO_CHECKOUT,
  text: I18n._uppercase(I18n.autoCheckoutAcceptedOffers || 'Offers Accepted - Auto Checkout'),
  badgeContent: tabBadgeCount[READY_FOR_AUTO_CHECKOUT],
});

const getWarehousesAcceptingOffers = (stockListSettings: any) => {
  let warehousesAcceptingOffers: string[] = [];

  Object.keys(stockListSettings).forEach((key) => {
    warehousesAcceptingOffers = warehousesAcceptingOffers.concat(
      stockListSettings[key].warehouseAcceptingOffer
    );
  });
  return warehousesAcceptingOffers;
};

export const getCartTabs = (stockListSettings: any, tabBadgeCount: any) => {
  let tabs = [...CART_TABS];
  if (isEmpty(tabBadgeCount)) return tabs;

  if (tabBadgeCount[READY_FOR_AUTO_CHECKOUT] > 0) {
    tabs.push(READY_FOR_AUTO_CHECKOUT);
  }

  const warehousesAcceptingOffers = getWarehousesAcceptingOffers(stockListSettings);
  if (warehousesAcceptingOffers.length === 0) {
    tabs = tabs.filter((tab) => {
      if (OFFER_TABS.includes(tab)) return tabBadgeCount[tab] > 0;
      return !OFFER_TABS.includes(tab);
    });
  }
  return tabs;
};

export const getCartTabsContent = (
  stockListSettings: any,
  I18n: any,
  tabBadgeCount: any,
  configState: any
) => {
  let tabsContent = tabs(I18n, tabBadgeCount, configState);
  if (isEmpty(tabBadgeCount)) return tabsContent;

  if (tabBadgeCount[READY_FOR_AUTO_CHECKOUT] > 0) {
    tabsContent.push(readyForAutoCheckoutTabObj(I18n, tabBadgeCount));
  }

  const warehousesAcceptingOffers = getWarehousesAcceptingOffers(stockListSettings);
  if (warehousesAcceptingOffers.length === 0) {
    tabsContent = tabsContent.filter((tabObj) => {
      if (OFFER_TABS.includes(tabObj.id)) return tabBadgeCount[tabObj.id] > 0;
      return !OFFER_TABS.includes(tabObj.id);
    });
  }
  return tabsContent;
};

export const getCartHeader = (
  stockListSettings: any,
  tabBadgeCount: any,
  I18n: any
) => {
  let tabs = [...CART_TABS];
  const warehousesAcceptingOffers = getWarehousesAcceptingOffers(stockListSettings);
  if (isEmpty(tabBadgeCount) && warehousesAcceptingOffers.length === 0) return I18n.cart || 'Cart';
  if (isEmpty(tabBadgeCount) && warehousesAcceptingOffers.length !== 0) return I18n.cartAndOffers || "Cart and Offers";
  if (warehousesAcceptingOffers.length === 0) {
    const isOfferTabCountAvailable = tabs.find((tab) => {
      if (OFFER_TABS.includes(tab)) return tabBadgeCount[tab] > 0;
      return false;
    });
    if(isOfferTabCountAvailable) return I18n.cartAndOffers || 'Cart and Offers';
    else return I18n.cart || 'Cart';
  }
  return I18n.cartAndOffers || 'Cart and Offers';
}

export function removeNonNumericCharacters(str: string) {
  // Use a regular expression to replace all non-numeric characters with an empty string
  return str.replace(/[^0-9]/g, '');
}

export function getI18nLabel(prefix: string, id: string, I18n: any) {
  return I18n[`${prefix}${id}`];
}

export const logoutUserWithoutAcknowledgement = async () => {
  try {
    await Auth.signOut();
    localStorage.clear();
    window.indexedDB.deleteDatabase('d2');
    window.location.href = '/';
  } catch (error: any) {
    console.error('error signing out: ', error);
  }
};

export const triggerBotActivityEvent = (error: any) => {
  const response = error.response;
  if (response?.status === 522 && response.headers?.['waf-block-header'] === 'true') {
    const detected = new CustomEvent('onBidActivityDetected', {
      detail: response,
    });
    document.dispatchEvent(detected);
  }
};

export const tryCatchWrapper = (fn: Function, fallback: Function) => {
  try {
    return fn();
  } catch (error) {
    return fallback(error);
  }
}
